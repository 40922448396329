<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialogAddOptionSingleChoiceParagraph"
        persistent
        max-width="600px"
        scrollable
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Add Short Answer</span>
            </v-col>
            <v-col cols="1">
              <v-icon
                @click="dialogAddOptionSingleChoiceParagraph = false"
                right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <template
                    v-for="(option, i) in dataAddOptionSingleChoiceParagraph"
                  >
                    <div :key="i" class="d-flex">
                      <v-text-field
                        :label="'Option' + (i + 1)"
                        :value="option"
                        @input="changeValue($event, i)"
                      ></v-text-field>
                      <v-btn class="mt-3" @click="deleteOption(i)"
                        ><v-icon>mdi-delete</v-icon></v-btn
                      >
                    </div>
                  </template>
                </v-col>
                <v-col cols="3">
                  <v-btn large @click="addOption">
                    <v-icon>mdi-plus</v-icon> Add Option
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="font-weight-bold"
              outlined
              color="blue darken-1"
              text
              @click="btnSaveOption"
              >Save</v-btn
            >
            <v-btn
              color="red darken-1"
              text
              @click="dialogAddOptionSingleChoiceParagraph = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "AddOptionSingleChoiceParagraphModal",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      refSingleChoiceInParagraph: (state) =>
        state.contentTestBuilderStore.refSingleChoiceInParagraph,
    }),
    ...mapGetters({
      dataAddOptionSingleChoiceParagraph:
        "contentTestBuilderStore/dataAddOptionSingleChoiceParagraph",
    }),
    dialogAddOptionSingleChoiceParagraph: {
      get() {
        return this.$store.state.contentTestBuilderStore
          .dialogAddOptionSingleChoiceParagraph;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setDialogAddOptionSingleChoiceParagraph",
          value
        );
      },
    },
    contentSingleChoiceInParagraph: {
      get() {
        return this.$store.state.contentTestBuilderStore
          .contentSingleChoiceInParagraph;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setContentSingleChoiceInParagraph",
          value
        );
      },
    },
  },
  methods: {
    changeValue(event, i) {
      this.dataAddOptionSingleChoiceParagraph[i] = event;
    },
    addOption() {
      let data = "";
      this.dataAddOptionSingleChoiceParagraph.push(data);
    },
    deleteOption(i) {
      if (this.dataAddOptionSingleChoiceParagraph.length <= 1) {
        this.$toasted.error("Phải có tối thiểu 1 option !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        return;
      }
      this.dataAddOptionSingleChoiceParagraph.splice(i, 1);
    },
    validate() {
      let flat = true;
      for (let i = 0; i < this.dataAddOptionSingleChoiceParagraph.length; i++) {
        if (this.dataAddOptionSingleChoiceParagraph[i] === "") {
          this.$toasted.error(
            "Hãy nhập thông tin cho option " + (i + 1) + " !!",
            { theme: "toasted-primary", position: "top-right", duration: 4000 }
          );
          flat = false;
        }
      }
      return flat;
    },
    btnSaveOption() {
      let validate = this.validate();
      if (!validate) {
        return;
      }
      let insertFirst = "{{";
      let insertLast = "}}";
      let editor = this.refSingleChoiceInParagraph.$_instance;
      // let offsetCurson = editor.model.document.selection.getFirstPosition().offset;

      let data_add_option = " " + insertFirst + this.chain(this.dataAddOptionSingleChoiceParagraph) + insertLast;

      editor.model.change((writer) => {
        let insertPosition = editor.model.document.selection.getFirstPosition();
        writer.insertText(data_add_option, insertPosition);
      });
      // this.refSingleChoiceInParagraph.$_instance.editing.view.focus();
      this.dialogAddOptionSingleChoiceParagraph = false;
    },
    chain(array) {
      let string = "";
      for (let index = 0; index < array.length; index++) {
        string += array[index].trim() + "//";
      }

      return string.substring(0, string.length - 2);
    },
  },
};
</script>

<style scoped></style>
