import { render, staticRenderFns } from "./AddOptionSingleChoiceParagraphModal.vue?vue&type=template&id=335b280d&scoped=true"
import script from "./AddOptionSingleChoiceParagraphModal.vue?vue&type=script&lang=js"
export * from "./AddOptionSingleChoiceParagraphModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "335b280d",
  null
  
)

export default component.exports